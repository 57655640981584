import App from "./App";
import React from "react";
import ReactDom from "react-dom";
import WrapperProvider from "./contexts/WrapperProvider";

ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("os-appeals-app")
);
