import React, { useState, useEffect, useReducer } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  BestLabel,
  Input,
  TextArea,
  DatePickerInput,
  LightButton,
  PrimaryButton,
  Loader,
  BestSelect,
  BestDropdown,
  Modal,
  Checkbox
} from "best-common-react";
import {
  createAppeal,
  deleteAppeal,
  getSingleAppeal,
  updateAppeal,
  createComment,
  getAllScoringAppealTags
} from "../../api/AppealsApi";
import { cutVideoForPlay } from "../../api/DiamondApi";
import {
  getGamesOnDate,
  getPlaysByGame,
  getPlayersByGamePkAndPlayId,
  getOfficialScorerByGame,
  getPlayersByGamePk
} from "../../api/StatsApi";
import { useAuth } from "../../contexts/AuthContext";
import { useAppeals } from "../../contexts/AppealsContext";
import { formatTwelveHourTime, getDateFromString } from "../../utils/DateUtils";
import { routeWithParams } from "../../utils/RouteUtils";
import { RouteConstants } from "../../constants/RouteConstants";
import { TitleRow, ButtonContainer, LoaderContainer } from "../elements/CommonElements";
import {
  AppealContainer,
  StyledLabel,
  StyledPrimaryButton,
  StyledDropdown,
  StyledDangerButton,
  StyledLightButton,
  Comments,
  FlexContainer,
  AddVideoInputContainer
} from "./AppealStyles";
import PlayerPicker from "../playerPicker/PlayerPicker";
import { AppealConstants } from "../../constants/AppealConstants";
import VideoList from "../video/VideoList";
import ListReducer from "../../reducers/ListReducer";
import { useRoles } from "../../contexts/RolesContext";
import Comment from "./Comment";

const Appeal: React.FC<RouteComponentProps> = ({ history }) => {
  const { isCommenter, isEditor, isApprover, isCreator } = useRoles();
  const { userInfo } = useAuth();

  const [requestedByOptions, setRequestedByOptions] = useState([]);
  const [requestedBy, setRequestedBy] = useState(null);
  const [sponsoredBy, setSponsoredBy] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState(null);
  const [requestedChange, setRequestedChange] = useState("");
  const [originalPlayScoring, setOriginalPlayScoring] = useState(null);
  const [revisedPlayScoring, setRevisedPlayScoring] = useState(null);
  const [date, setDate] = useState(null);
  const [gamesOptions, setGamesOptions] = useState([]);
  const [game, setGame] = useState(null);
  const [playsOptions, setPlaysOptions] = useState([]);
  const [play, setPlay] = useState(null);
  const [allPlays, setAllPlays] = useState(false);
  const [officialScorer, setOfficialScorer] = useState(null);
  const [players, setPlayers] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [playPositions, setPlayPositions] = useState(null);
  const [videos, videosDispatch] = useReducer(ListReducer, []);
  const [newVideoLabel, setNewVideoLabel] = useState("");
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [videoCuts, setVideoCuts] = useState([]);

  const [status, setStatus] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [decision, setDecision] = useState("");
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const [callPending, setCallPending] = useState(false);
  const [appealTags, setAppealTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [showResequenceModal, setShowResequenceModal] = useState(false);

  const { appeal, setAppeal, isEdit, setIsEdit, showModal, setShowModal, tags, setTags } = useAppeals();

  useEffect(() => {
    const appealId = window.location.pathname.split("/")[2];
    if (!appeal && appealId !== "new") {
      getSingleAppeal(parseInt(appealId)).then((response: Appeal) => {
        setAppeal(response);
        setIsEdit(true);
      });
    } else if (!appeal && appealId === "new") {
      setIsEdit(false);
    }

    if (!tags || tags.length === 0) {
      getAllScoringAppealTags().then(response => {
        setTags(response.data);
      });
    }
  }, []);

  const createGamesOptions = (gamesList: Game[]) => {
    setGamesOptions(
      gamesList.map((game: Game) => {
        const startTime = formatTwelveHourTime(new Date(game.gameDate));
        return {
          value: game.gamePk,
          homeTeam: game.homeTeam,
          awayTeam: game.awayTeam,
          label: `${game.awayTeam.abbreviation} @ ${game.homeTeam.abbreviation} | ${startTime}`,
          ...game
        };
      })
    );
  };

  const createPlaysOptions = (playList: Play[]) => {
    setPlaysOptions(
      playList.map((play: Play) => ({
        value: play.playId,
        label: `${play.topInning ? "T" : "B"}${play.inningNumber} | ${play.pitcher.fullName} | ${
          play.batter.fullName
        } | ${play.description}`,
        ...play
      }))
    );
  };

  const createRequestedByOptions = (playerList: Player[]) => {
    setRequestedByOptions(
      playerList.map((player: Player) => ({
        value: player.id,
        label: player.fullName,
        ...player
      }))
    );
  };

  useEffect(() => {
    if (appeal) {
      setSequenceNumber(appeal.sequenceNumber);
      setSponsoredBy(appeal.sponsoredBy);
      setRequestedChange(appeal.requestedChange);
      setOriginalPlayScoring(appeal.originalPlayScoring);
      setRevisedPlayScoring(appeal.revisedPlayScoring);
      videosDispatch({ type: "replaceList", item: appeal.videos });
      setComments(
        appeal.comments.sort((a: AppealComment, b: AppealComment) => {
          return a.createdTs > b.createdTs ? -1 : 1;
        })
      );
      setStatus(AppealConstants.STATUS_OPTIONS.find(o => o.label === appeal.status));
      setResolution(AppealConstants.RESOLUTION_OPTIONS.find(o => o.value === appeal.resolution));
      setDecision(appeal.decision);
      setAppealTags(appeal.tags);
    }
  }, [appeal]);

  useEffect(() => {
    if (appeal && !date) {
      setDate(getDateFromString(appeal.gameDate));
    }

    if (date) {
      getGamesOnDate(date).then((response: Game[]) => {
        createGamesOptions(response);
        setGame(null);
        setPlay(null);

        if (!appeal) {
          setPlayers([]);
        }
      });
    }
  }, [appeal, date]);

  useEffect(() => {
    if (appeal && gamesOptions && !game) {
      setGame(gamesOptions.find(go => go.gamePk === appeal.gamePk));
    }

    if (appeal && requestedByOptions && !requestedBy) {
      setRequestedBy(requestedByOptions.find(rbo => rbo.id === appeal.requestedBy.id));
    }

    if (game) {
      getPlaysByGame(game.value, allPlays).then((response: Play[]) => {
        createPlaysOptions(response);
        setPlay(null);

        if (!appeal) {
          setPlayers([]);
        }
      });

      getPlayersByGamePk(game.value).then((response: Player[]) => {
        createRequestedByOptions(response);
      });
    }
  }, [game, gamesOptions, allPlays]);

  useEffect(() => {
    if (appeal && appeal.requestedBy) {
      setRequestedBy(requestedByOptions.find(o => o.value === appeal.requestedBy.id));
    } else {
      setRequestedBy(null);
    }
  }, [requestedByOptions]);

  useEffect(() => {
    if (game) {
      if (appeal && appeal.id) {
        setOfficialScorer(appeal.officialScorer);
      } else {
        setOfficialScorer(null);
        getOfficialScorerByGame(game.value).then((response: Person) => {
          setOfficialScorer(response);
        });
      }
    }
  }, [appeal, game]);

  useEffect(() => {
    if (appeal && playsOptions && !play) {
      setPlay(playsOptions.find(po => po.playId === appeal.playId));
    }

    if (game && play) {
      getPlayersByGamePkAndPlayId(game.value, play.value).then((response: PlayPositions) => {
        setPlayPositions(response);

        if (!appeal) {
          setPlayers([]);
        }
      });
    }
  }, [game, play]);

  useEffect(() => {
    if (appeal && appeal.players && playPositions) {
      setPlayers(appeal.players);
    }
  }, [appeal, playPositions]);

  useEffect(() => {
    if (appeal && status) {
      setAllPlays(true);
      if (status.value !== AppealConstants.STATUSES.RESOLVED) {
        setResolution(null);
      }
    }
  }, [appeal, status]);

  useEffect(() => {
    setSaveDisabled(
      !date ||
        !game ||
        !play ||
        !officialScorer ||
        requestedBy === "" ||
        requestedChange === "" ||
        (status &&
          status.value === AppealConstants.STATUSES.RESOLVED &&
          (!resolution || decision === "" || decision === null))
    );
  }, [appeal, date, game, play, requestedBy, requestedChange, status, resolution, decision, officialScorer]);

  useEffect(() => {
    setTagOptions(
      tags.map(tag => {
        return {
          value: tag.id,
          label: tag.label,
          ...tag
        };
      })
    );
  }, [tags]);

  const saveAppeal = () => {
    const newAppeal: Appeal = {
      appealType: "scoringAppeal",
      id: appeal ? appeal.id : null,
      sequenceNumber: sequenceNumber,
      status: status ? status.label : null,
      resolution: resolution ? resolution.value : null,
      decision: decision,
      gamePk: game.value,
      gameDate: date,
      homeTeam: game.homeTeam,
      awayTeam: game.awayTeam,
      playId: play.value,
      officialScorer: officialScorer,
      requestedBy: requestedBy,
      sponsoredBy: sponsoredBy,
      requestedChange: requestedChange,
      originalPlayScoring: originalPlayScoring,
      revisedPlayScoring: revisedPlayScoring,
      description: play.description,
      players: players,
      inning: play.inningNumber,
      topInning: play.topInning,
      batter: play.batter,
      pitcher: play.pitcher,
      videos: videos,
      tags: appealTags,
      createdBy: appeal ? appeal.createdBy : null,
      createdTs: appeal ? appeal.createdTs : null,
      startedTs: appeal ? appeal.startedTs : null
    };

    const saveCall = appeal ? updateAppeal : createAppeal;
    saveCall(newAppeal).then(
      response => {
        if (!appeal) {
          const appealId = response.headers.location.split("/").pop();
          getSingleAppeal(appealId).then((response: Appeal) => {
            setAppeal(response);
            setIsEdit(true);
            history.push(routeWithParams(RouteConstants.APPEAL, { key: "id", value: appealId }));
          });
        } else {
          setAppeal(null);
          history.push(RouteConstants.BASE);
        }
      },
      error => {
        throw new Error("There was an error saving the appeal: " + error);
      }
    );
  };

  const cancel = () => {
    setAppeal(null);
    history.push(RouteConstants.BASE);
  };

  const confirmDelete = () => {
    deleteAppeal(appeal.id).then(
      response => {
        setShowModal(false);
        setAppeal(null);
        history.push(RouteConstants.BASE);
      },
      error => {
        throw new Error("There was an error deleting the appeal: " + error);
      }
    );
  };

  const cutVideo = () => {
    setCallPending(true);
    cutVideoForPlay(appeal.id, videoCuts).then(
      (response: Video[]) => {
        setCallPending(false);
        videosDispatch({ type: "replaceList", item: response });
      },
      error => {
        setCallPending(false);
        throw new Error("There was an error cutting video for the appeal: " + error);
      }
    );
  };

  const deleteModal = () => {
    return (
      <Modal show={showModal} size="md">
        <Modal.Header>Deleting Appeal</Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this appeal?</div>
          <div>This action cannot be undone.</div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonContainer>
            <StyledPrimaryButton onClick={confirmDelete}>Confirm</StyledPrimaryButton>
            <LightButton onClick={() => setShowModal(false)}>Cancel</LightButton>
          </ButtonContainer>
        </Modal.Footer>
      </Modal>
    );
  };

  const resequenceModal = () => {
    return (
      <Modal show={showResequenceModal} size="md">
        <Modal.Header>Changing Appeal #</Modal.Header>
        <Modal.Body>
          <div>
            By changing the appeal # of this appeal, you will cause all other appeals for this season to be renumbered
            to keep them sequential.
          </div>
          <div>Are you sure you want to update the appeal # of all appeals for this season?</div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonContainer>
            <StyledPrimaryButton onClick={saveAppeal}>Confirm</StyledPrimaryButton>
            <LightButton onClick={() => setShowResequenceModal(false)}>Cancel</LightButton>
          </ButtonContainer>
        </Modal.Footer>
      </Modal>
    );
  };

  const playControl = () => {
    if (isEdit && isEditor()) {
      let description = "";
      if (appeal) {
        description = description
          .concat(appeal.topInning ? "T" : "B")
          .concat(appeal.inning.toString())
          .concat(" | ");
        if (appeal.pitcher) {
          description = description.concat(appeal.pitcher.fullName).concat(" | ");
        }
        if (appeal.batter) {
          description = description.concat(appeal.batter.fullName).concat(" | ");
        }
        description = description.concat(appeal.description);
      }
      return (
        <div style={{ width: "100%" }}>
          <StyledLabel htmlFor="requested-change" required>
            Inning | Pitcher | Batter | Result:
          </StyledLabel>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: "2", marginRight: "20px" }}>
              <TextArea disabled={true} name="play" title="Inning | Pitcher | Batter | Result:" value={description} />
            </div>
            <LightButton
              disabled={!play}
              onClick={() => noradPlay(play)}
              style={{
                minWidth: "100px"
              }}
            >
              NORAD Play
            </LightButton>
          </div>
        </div>
      );
    } else if (date && date !== "" && game) {
      return (
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div style={{ flex: "2" }}>
            <StyledLabel htmlFor="play" required style={{ marginTop: "0px" }}>
              Inning | Pitcher | Batter | Result:
            </StyledLabel>
            <BestSelect
              name="play"
              value={isEdit && playsOptions.length === 0 ? "Loading" : play ? play : ""}
              onChange={setPlay}
              options={playsOptions.length === 0 ? ["Loading..."] : playsOptions}
              isDisabled={isEdit}
              styles={gamePlayDropdownStyles}
              required
              isSearchable
            />
          </div>
          <div style={checkboxStyles}>
            <Checkbox label="Show All Plays" onChange={() => setAllPlays(!allPlays)} checked={allPlays} />
          </div>
          <LightButton
            disabled={!play}
            onClick={() => noradPlay(play)}
            style={{
              marginTop: "24px",
              minWidth: "100px"
            }}
          >
            NORAD
          </LightButton>
        </div>
      );
    } else return null;
  };

  const postComment = () => {
    if (appeal && appeal.id && newComment !== "") {
      createComment(appeal.id, { text: newComment }).then(
        response => {
          setNewComment("");
          setComments([
            {
              id: response.headers.location.split("/")[4],
              fullName: "You",
              createdBy: userInfo.email,
              text: newComment
            },
            ...comments
          ]);
        },
        error => {
          throw new Error("There was an issue saving your comment: " + error);
        }
      );
    }
  };

  const noradGame = (game: Game) => {
    const url = "https://norad.mlbinfra.com/games/" + game.gamePk + "/plays";
    window.open(url, "_blank");
  };

  const noradPlay = (play: Play) => {
    const url = "https://norad.mlbinfra.com/games/" + play.gamePk + "/plays/" + play.playId;
    window.open(url, "_blank");
  };

  const deleteVideo = (video: Video) => {
    videosDispatch({ type: "remove", item: video });
  };

  const addVideo = () => {
    const newVideo = {
      label: newVideoLabel,
      url: newVideoUrl
    };
    setNewVideoLabel("");
    setNewVideoUrl("");
    videosDispatch({ type: "add", item: newVideo });
  };

  const checkboxStyles = {
    marginTop: "24px",
    marginRight: "20px",
    minWidth: "120px"
  };

  const gamePlayDropdownStyles = {
    control: {
      marginRight: "20px"
    }
  };

  const resolutionStyles = {
    container: {
      display: "inline-block",
      width: "275px"
    },
    singleValue: {
      color: resolution
        ? resolution.value === AppealConstants.RESOLUTIONS.WITHDRAWN
          ? "#000000"
          : resolution.value === AppealConstants.RESOLUTIONS.UPHELD
          ? "#2b77eb"
          : "#BF0D3E"
        : ""
    }
  };

  const statusStyles = {
    container: {
      display: "inline-block",
      width: "230px"
    },
    singleValue: {
      color: status
        ? status.value === AppealConstants.STATUSES.RESOLVED
          ? "#000000"
          : status.value === AppealConstants.STATUSES.NEEDS_INFORMATION
          ? "#BF0D3E"
          : "#2b77eb"
        : ""
    }
  };

  const videoCutStyles = {
    container: {
      display: "inline-block",
      width: "150px",
      fontSize: "1rem",
      marginRight: "20px"
    }
  };

  const navigateToAppeal = (appealId: number) => {
    history.push(`${routeWithParams(RouteConstants.APPEAL, { key: "id", value: appealId })}`);
    setAppeal(null);
    setDate(null);
    getSingleAppeal(appealId).then((response: Appeal) => {
      setAppeal(response);
      setIsEdit(true);
    });
  };

  return (
    <FlexContainer>
      {isEdit ? (
        <Comments>
          <h4 style={{ marginTop: "20px" }}>Comments: {comments ? comments.length : "0"}</h4>
          <div style={isCommenter() ? { display: "block" } : { display: "none" }}>
            <StyledLabel htmlFor="new-comment">Add a comment:</StyledLabel>
            <TextArea
              name="new-comment"
              value={newComment}
              onChange={(event: any) => setNewComment(event.target.value)}
            />
            <PrimaryButton
              style={{ marginTop: "10px", marginBottom: "10px" }}
              disabled={newComment === ""}
              onClick={postComment}
            >
              Add Comment
            </PrimaryButton>
          </div>
          {comments.map((comment: AppealComment, index: number) => (
            <Comment comment={comment} index={index} setComments={setComments} />
          ))}
        </Comments>
      ) : null}
      <AppealContainer style={isEdit && isCommenter() ? null : { width: "700px" }}>
        {deleteModal()}
        {isCreator() ? resequenceModal() : null}
        <TitleRow>
          {isEdit ? (
            <>
              <span style={{ marginTop: "5px", marginRight: "5px" }}>Appeal Status: </span>
              <BestSelect
                options={AppealConstants.STATUS_OPTIONS}
                onChange={setStatus}
                value={status}
                styles={statusStyles}
                isDisabled={!isEditor()}
              />
              {isApprover() && status && status.value === AppealConstants.STATUSES.RESOLVED ? (
                <>
                  <span style={{ marginTop: "5px", marginLeft: "20px", marginRight: "5px" }}>
                    Resolution: <span style={{ color: "#BF0D3E" }}>*</span>
                  </span>
                  <BestSelect
                    options={AppealConstants.RESOLUTION_OPTIONS}
                    onChange={setResolution}
                    value={resolution}
                    styles={resolutionStyles}
                  />
                </>
              ) : null}
            </>
          ) : (
            `Create Appeal`
          )}
          <ButtonContainer>
            {isEditor() ? (
              <StyledPrimaryButton
                onClick={
                  isCreator() && appeal && sequenceNumber !== appeal.sequenceNumber
                    ? () => setShowResequenceModal(true)
                    : saveAppeal
                }
                disabled={callPending || saveDisabled}
              >
                Save
              </StyledPrimaryButton>
            ) : null}
            {isEditor() && appeal && appeal.id ? (
              <>
                <BestSelect
                  options={AppealConstants.VIDEO_CUT_OPTIONS}
                  placeholder="Select cut..."
                  onChange={setVideoCuts}
                  value={videoCuts}
                  styles={videoCutStyles}
                  id="video-cut-select"
                  isMulti
                />
                <StyledLightButton onClick={cutVideo} disabled={callPending}>
                  Cut Video
                </StyledLightButton>
              </>
            ) : null}
            {isCreator() || isApprover() ? (
              <StyledDangerButton onClick={() => setShowModal(true)} disabled={callPending}>
                Delete
              </StyledDangerButton>
            ) : null}
            <LightButton onClick={cancel} disabled={callPending}>
              Cancel
            </LightButton>
          </ButtonContainer>
        </TitleRow>
        {isApprover() && status && status.value === AppealConstants.STATUSES.RESOLVED ? (
          <>
            <BestLabel htmlFor="decision" required>
              Decision:
            </BestLabel>
            <TextArea
              name="decision"
              style={{ marginBottom: "20px" }}
              value={decision}
              onChange={(event: any) => setDecision(event.target.value)}
            />
          </>
        ) : null}
        <div style={{ display: "flex", marginTop: "20px" }}>
          {!!appeal?.previousAppealId && (
            <StyledLightButton style={{ minWidth: 80 }} onClick={() => navigateToAppeal(appeal?.previousAppealId)}>
              Previous
            </StyledLightButton>
          )}
          {!!appeal?.nextAppealId && (
            <StyledLightButton style={{ minWidth: 80 }} onClick={() => navigateToAppeal(appeal?.nextAppealId)}>
              Next
            </StyledLightButton>
          )}
        </div>
        <div style={{ display: "flex", marginTop: "20px" }}>
          {isApprover() && appeal && appeal.sequenceNumber ? (
            <div>
              <BestLabel htmlFor="sequence-number">Appeal #:</BestLabel>
              <Input
                disabled={!isCreator()}
                name="sequence-number"
                value={sequenceNumber}
                onChange={(event: any) => setSequenceNumber(event.target.value)}
                style={{ marginRight: "20px", width: "75px" }}
              />
            </div>
          ) : null}
          <div>
            <BestLabel htmlFor="game-date" required>
              Game Date:
            </BestLabel>
            <DatePickerInput name="game-date" value={date ? date : ""} onChange={setDate} disabled={isEdit} />
          </div>
        </div>
        {date && date !== "" ? (
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ flex: "2" }}>
              <StyledDropdown
                name="game"
                title="Away @ Home | Game start time:"
                value={isEdit && gamesOptions.length === 0 ? "Loading..." : game ? game : ""}
                onChange={setGame}
                options={gamesOptions.length === 0 ? ["Loading..."] : gamesOptions}
                isDisabled={isEdit}
                styles={gamePlayDropdownStyles}
                required
              />
            </div>
            <LightButton
              disabled={!game}
              onClick={() => noradGame(game)}
              style={{
                marginTop: "24px",
                minWidth: "100px"
              }}
            >
              NORAD Game
            </LightButton>
          </div>
        ) : null}
        {playControl()}
        {officialScorer ? (
          <>
            <StyledLabel htmlFor="official-scorer">Official Scorer:</StyledLabel>
            <Input disabled={true} name="official-scorer" value={officialScorer.fullName} onChange={() => {}} />
          </>
        ) : null}
        {date && date !== "" && game ? (
          <>
            <StyledLabel htmlFor="requested-by" required>
              Requested By:
            </StyledLabel>
            <BestSelect
              name="requested-by"
              value={isEdit && requestedByOptions.length === 0 ? "Loading..." : requestedBy ? requestedBy : ""}
              onChange={setRequestedBy}
              options={requestedByOptions.length === 0 ? ["Loading..."] : requestedByOptions}
              isDisabled={isEdit}
              required
              isSearchable
            />
          </>
        ) : null}
        <StyledLabel htmlFor="sponsored-by">Sponsored By:</StyledLabel>
        <Input
          name="sponsored-by"
          value={sponsoredBy}
          onChange={(event: any) => setSponsoredBy(event.target.value)}
          readOnly={!isEditor()}
        />
        <StyledLabel htmlFor="requested-change" required>
          Requested Change:
        </StyledLabel>
        <TextArea
          name="requested-change"
          value={requestedChange}
          onChange={(event: any) => setRequestedChange(event.target.value)}
          readOnly={!isEditor()}
        />
        <StyledLabel htmlFor="original-play-scoring">Original Play Scoring</StyledLabel>
        <Input
          name="original-play-scoring"
          value={originalPlayScoring}
          onChange={(event: any) => setOriginalPlayScoring(event.target.value)}
          readOnly={!isEditor()}
        />
        <StyledLabel htmlFor="revised-play-scoring">Revised Play Scoring</StyledLabel>
        <Input
          name="revised-play-scoring"
          value={revisedPlayScoring}
          onChange={(event: any) => setRevisedPlayScoring(event.target.value)}
          readOnly={!isEditor()}
        />
        {isEdit || (!isEdit && play) ? (
          <>
            <StyledLabel htmlFor="selected-players">Players Involved:</StyledLabel>
            {game && play && playPositions ? (
              <PlayerPicker
                game={game}
                play={play}
                playPositions={playPositions}
                value={players}
                onChange={setPlayers}
                readOnly={!isEditor()}
              />
            ) : (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </>
        ) : null}
        {play ? (
          <>
            <VideoList videos={videos} onDelete={deleteVideo} readOnly={!isEditor()} />
            {isEditor() ? (
              <div>
                <AddVideoInputContainer>
                  <StyledLabel htmlFor="new-video-label">Label:</StyledLabel>
                  <Input
                    name="new-video-label"
                    value={newVideoLabel}
                    onChange={(event: any) => setNewVideoLabel(event.target.value)}
                  />
                </AddVideoInputContainer>
                <AddVideoInputContainer>
                  <StyledLabel htmlFor="new-video-url">URL:</StyledLabel>
                  <Input
                    name="new-video-url"
                    value={newVideoUrl}
                    onChange={(event: any) => setNewVideoUrl(event.target.value)}
                  />
                </AddVideoInputContainer>
                <LightButton onClick={addVideo} style={{ marginBottom: "5px" }}>
                  Add
                </LightButton>
              </div>
            ) : null}
            <BestDropdown
              title="Tags"
              value={appealTags}
              isMulti={true}
              options={tagOptions}
              onChange={setAppealTags}
            />
          </>
        ) : null}
      </AppealContainer>
    </FlexContainer>
  );
};

export default withRouter(Appeal);
