import React, { useState, useContext, createContext, Dispatch, SetStateAction } from "react";

type UserInfo = {
  accessToken: string;
  email: string;
};

type UserContext = {
  userInfo: UserInfo;
  setUserInfo: Dispatch<SetStateAction<UserInfo>>;
  loggedIn: boolean;
  login: (data: UserInfo) => void;
  logout: () => void;
};

const AuthContext = createContext<UserContext>({
  userInfo: null,
  setUserInfo: null,
  loggedIn: false,
  login: null,
  logout: null
});

const initialState = {
  userInfo: {
    accessToken: "",
    email: ""
  },
  loggedIn: false,
  authToken: "",
  email: ""
};

const AuthContextProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(initialState.userInfo);
  const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);

  const login = (userData: UserInfo) => {
    if (userData) {
      setUserInfo(userData);
      setLoggedIn(true);
    }
  };

  const logout = () => {
    localStorage.clear();
    setUserInfo(initialState.userInfo);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo, loggedIn, login, logout }}>{children}</AuthContext.Provider>
  );
};

const useAuth = (): UserContext => {
  const authContext = useContext<UserContext>(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }
  return authContext;
};

export { AuthContext, AuthContextProvider, useAuth };
