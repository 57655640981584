import React, { useEffect } from "react";
import { getAllScoringAppealTags } from "../api/AppealsApi";
import { useAppeals } from "../contexts/AppealsContext";
import AppealsTable from "./AppealsTable";

const AppealsComponent: React.FC = () => {
  const { setTags } = useAppeals();

  useEffect(() => {
    getAllScoringAppealTags().then(response => {
      setTags(response.data);
    });
  }, []);

  return (
    <>
      <AppealsTable />
    </>
  );
};

export default AppealsComponent;
