import React, { useState, useEffect } from "react";
import { StyledPlayer, Position, Name, TeamRow, TeamLogo, TeamPlayers } from "./PlayerPickerStyles";

type PlayerProps = {
  player: Player;
  selected: boolean;
  value: ScoringAppealPlayerDto[];
  onChange: Function;
  readOnly: boolean;
};
const Player: React.FC<PlayerProps> = ({ player, selected, value, onChange, readOnly }) => {
  const [isSelected, setIsSelected] = useState(selected);

  const togglePlayer = (isPlayerSelected: boolean) => {
    setIsSelected(isPlayerSelected);
    if (isPlayerSelected) {
      value.push({ playerId: player.id });
    } else {
      value.splice(value.indexOf(value.find(p => p.playerId === player.id)), 1);
    }
    onChange(value);
  };

  const getPositionAbbreviation = (position: string): string => {
    let abbreviation = position;
    if (position === "Batter") {
      abbreviation = "B";
    } else if (position.split(" ")[0] === "Runner") {
      abbreviation = `R${position.split(" ")[1].split("")[0]}`;
    }
    return abbreviation;
  };

  return (
    <StyledPlayer selected={isSelected} onClick={readOnly ? null : () => togglePlayer(!isSelected)} readOnly={readOnly}>
      <Position selected={isSelected}>{getPositionAbbreviation(player.position)}</Position>
      <Name>{player.fullName}</Name>
    </StyledPlayer>
  );
};

type PlayerPickerProps = {
  game: Game;
  play: Play;
  playPositions: PlayPositions;
  value: ScoringAppealPlayerDto[];
  onChange: Function;
  readOnly: boolean;
};

type PlayerPickerTeam = {
  id: number;
  players: Player[];
};

const PlayerPicker: React.FC<PlayerPickerProps> = ({ game, play, playPositions, value, onChange, readOnly }) => {
  const offensePositions = ["Batter", "Runner 1B", "Runner 2B", "Runner 3B"];
  const defensePositions = ["P", "C", "1B", "2B", "3B", "SS", "LF", "CF", "RF"];

  const [playDetails, setPlayDetails] = useState(null);

  useEffect(() => {
    setPlayDetails({
      offenseTeamId: play.topInning ? game.awayTeam.id : game.homeTeam.id,
      defenseTeamId: play.topInning ? game.homeTeam.id : game.awayTeam.id,
      teams: {
        offense: {
          id: play.topInning ? game.awayTeam.id : game.homeTeam.id,
          players: Object.values(playPositions).filter(player =>
            player && player.position ? offensePositions.indexOf(player.position) !== -1 : null
          )
        },
        defense: {
          id: play.topInning ? game.homeTeam.id : game.awayTeam.id,
          players: Object.values(playPositions).filter(player =>
            player && player.position ? defensePositions.indexOf(player.position) !== -1 : null
          )
        }
      }
    });
  }, [value]);

  const isPlayerSelected = (player: Player): boolean => {
    const foundPlayer = value.find((p: ScoringAppealPlayerDto) => p.playerId === player.id);
    return foundPlayer !== null && foundPlayer !== undefined;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {playDetails
        ? Object.values(playDetails.teams).map((team: PlayerPickerTeam) => (
            <TeamRow key={team.id}>
              <TeamLogo src={`https://www.mlbstatic.com/team-logos/${team.id}.svg`} />
              <TeamPlayers>
                {team.players.map((player: Player) =>
                  player && player.fullName ? (
                    <Player
                      key={player.id}
                      player={player}
                      value={value}
                      selected={isPlayerSelected(player)}
                      onChange={onChange}
                      readOnly={readOnly}
                    />
                  ) : null
                )}
              </TeamPlayers>
            </TeamRow>
          ))
        : null}
    </div>
  );
};

export default PlayerPicker;
