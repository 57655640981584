type RouteParam = {
  key: string;
  value: string | number;
};

export const routeWithParams = (route: string, ...params: RouteParam[]) => {
  params.map(param => {
    route = route.replace(`:${param.key}`, param.value.toString());
  });
  return route;
};
