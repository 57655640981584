import React from "react";
import { StyledLabel } from "../appeal/AppealStyles";
import { DataTable } from "best-common-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

type VideoListProps = {
  videos: Video[];
  onDelete: Function;
  readOnly: boolean;
};

const VideoList: React.FC<VideoListProps> = ({ videos, onDelete, readOnly }) => {
  const columns: Array<any> = [
    {
      name: "Label",
      key: "label",
      width: 150
    },
    {
      name: "URL",
      key: "url",
      width: 635,
      formatter: (data: any) => (
        <span>
          <a href={data.row.url} target="_blank">
            {data.row.url}
          </a>
        </span>
      )
    },
    readOnly
      ? null
      : {
          name: "",
          key: "actions",
          width: 50,
          formatter: (data: any) => (
            <span>
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={() => onDelete(data.row)}
                style={{ color: "#dc3545", cursor: "pointer" }}
              />
            </span>
          )
        }
  ].map(c => ({ ...c, ...defaultColumnProperties }));

  const defaultColumnProperties = {
    sortable: false,
    dragable: false
  };

  return (
    <>
      <StyledLabel htmlFor="videos">Videos:</StyledLabel>
      {videos && videos.length ? (
        <DataTable columns={columns} data={videos} height={videos.length * 35 + 40} />
      ) : (
        <div>No videos are attached.</div>
      )}
    </>
  );
};

export default VideoList;
