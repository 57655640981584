import styled from "styled-components";

export const TeamRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

export const TeamLogo = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 20px;
`;

export const TeamPlayers = styled.div`
  flex: 2;
`;

type StyledPlayerProps = {
  selected: boolean;
  readOnly: boolean;
};
export const StyledPlayer = styled.span<StyledPlayerProps>`
  display: inline-flex;
  flex-direction: row;
  border: thin solid ${props => (props.selected ? props.theme.green : props.theme["mlb-blue"])};
  border-radius: 3px;
  height: 50px;
  line-height: 48px;
  cursor: ${props => (props.readOnly ? "initial" : "pointer")};
  margin: 0 10px 10px 0;

  :hover {
    box-shadow: ${props => (props.readOnly ? "none" : "2px 2px 5px 0px rgba(0, 0, 0, 0.75)")};
  }
`;

export const Position = styled.span<StyledPlayerProps>`
  font-weight: bold;
  font-size: 2rem;
  display: inline-block;
  width: 50px;
  text-align: center;
  background-color: ${props => (props.selected ? props.theme.green : props.theme["mlb-blue"])};
  color: white;
`;

export const Name = styled.div`
  display: inline-flex;
  flex: 2;
  padding: 0 10px 0 10px;
`;
