import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "best-common-react";
import { handleLogin } from "../customHooks/UseOkta";

const LoginBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 300px;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  width: 300px;
  background-color: white;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
`;

const LoginComponent: React.FC = () => {
  return (
    <LoginBox>
      <PrimaryButton onClick={() => handleLogin(null)}>Login With Okta SSO</PrimaryButton>
    </LoginBox>
  );
};

export default LoginComponent;
