import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { RouteConstants } from "./constants/RouteConstants";
import PrivateRoute from "./components/PrivateRoute";
import LoginContainer from "./containers/LoginContainer";
import AppealsContainer from "./containers/AppealsContainer";
import AppealsComponent from "./components/AppealsComponent";
import Appeal from "./components/appeal/Appeal";
import { AppealsContextProvider } from "./contexts/AppealsContext";
import OktaLogin from "./components/OktaLogin";
import { useAuth } from "./contexts/AuthContext";
import { getLoggedInUser } from "./api/UserApi";
import { useRoles } from "./contexts/RolesContext";

const App = () => {
  const { loggedIn } = useAuth();
  const { setUserRoles } = useRoles();

  useEffect(() => {
    getLoggedInUser().then(data => {
      setUserRoles(data.userRoles);
    });
  }, [loggedIn]);

  return (
    <OktaLogin>
      <Switch>
        {!loggedIn ? (
          <Route exact path={RouteConstants.LOGIN} component={LoginContainer} />
        ) : (
          <AppealsContextProvider>
            <AppealsContainer>
              <PrivateRoute exact path={RouteConstants.BASE} component={AppealsComponent} />
              <PrivateRoute exact path={RouteConstants.APPEAL} component={Appeal} />
            </AppealsContainer>
          </AppealsContextProvider>
        )}
      </Switch>
    </OktaLogin>
  );
};

export default App;
