import moment from "moment";

const getSeasonOptions = () => {
  let SEASON_OPTIONS = [
    {
      value: 0,
      label: "All"
    }
  ];
  let current = moment();
  let year = current.year();
  if (current.month() === 0 || (current.month() === 1 && current.date() < 15)) {
    year--;
  }
  while (year >= 2020) {
    SEASON_OPTIONS.push({
      value: year,
      label: year.toString()
    });
    year--;
  }
  return SEASON_OPTIONS;
};

export const AppealConstants = {
  TYPES: {
    SCORINGAPPEAL: "Scoring Appeal"
  },
  RESOLUTIONS: {
    WITHDRAWN: "Withdrawn",
    UPHELD: "Upheld",
    OVERTURNED: "Overturned",
    ADJUSTED: "Adjusted",
    OFFICIAL_SCORER_CHANGE: "Official Scorer Change"
  },
  RESOLUTION_OPTIONS: [
    {
      value: "Withdrawn",
      label: "Withdrawn"
    },
    {
      value: "Upheld",
      label: "Upheld"
    },
    {
      value: "Overturned",
      label: "Overturned"
    },
    {
      value: "Adjusted",
      label: "Adjusted"
    },
    {
      value: "Official Scorer Change",
      label: "Official Scorer Change"
    }
  ],
  SEASON_OPTIONS: getSeasonOptions(),
  STATUSES: {
    OPEN: 1,
    IN_PROGRESS: 2,
    NEEDS_INFORMATION: 3,
    RESOLVED: 4
  },
  STATUS_OPTIONS: [
    {
      value: 1,
      label: "Open"
    },
    {
      value: 2,
      label: "In Progress"
    },
    {
      value: 3,
      label: "Needs Information"
    },
    {
      value: 4,
      label: "Resolved"
    }
  ],
  FIELDS: {
    SEQUENCE: "sequenceNumber",
    GAME_DATE: "gameDate",
    HOME_TEAM: "homeTeamId",
    AWAY_TEAM: "awayTeamId",
    REQUESTED_BY: "requestedBy",
    REQUESTED_CHANGE: "requestedChange",
    STATUS: "status",
    DECISION: "decision",
    RESOLUTION: "resolution",
    COMMENT_STATUS: "commentStatus"
  },
  VIDEO_CUT_OPTIONS: [
    {
      value: "HD-4-LIVE",
      label: "Home"
    },
    {
      value: "AD-4-LIVE",
      label: "Away"
    },
    {
      value: "HH-4-LIVE",
      label: "High Home"
    },
    {
      value: "ND-4-LIVE",
      label: "National"
    }
  ]
};
