import styled from "styled-components";
import { BestLabel, PrimaryButton, BestDropdown, LightButton, DangerButton } from "best-common-react";
import { AppealConstants } from "../../constants/AppealConstants";

type AppealStatusProps = {
  status: string;
};
export const AppealStatus = styled.span<AppealStatusProps>`
  display: inline-block;
  color: ${props => (props.status === AppealConstants.STATUSES.OPEN ? props.theme["sky-blue"] : "black")};
`;

type DaysOpenProps = {
  days: number;
};

export const DaysOpen = styled.span<DaysOpenProps>`
  color: ${props => (props.days >= 7 ? "red" : "black")};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AppealContainer = styled.div`
  display: inline-block;
  flex: 2;
`;

export const StyledLabel = styled(BestLabel)`
  margin-top: 20px;
`;

export const Buttons = styled.div`
  margin-top: 30px;
`;

export const StyledDangerButton = styled(DangerButton)`
  margin-right: 20px;
`;

export const StyledLightButton = styled(LightButton)`
  margin-right: 20px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 20px;
`;

export const StyledDropdown = styled(BestDropdown)`
  margin-bottom: 20px;
`;

export const Comments = styled.div`
  display: inline-block;
  margin-right: 30px;
  padding-right: 30px;
  border-right: thin solid ${props => props.theme.grey};
  width: 400px;
`;

type CommentContainerProps = {
  index: number;
  currentUser: boolean;
};
export const CommentContainer = styled.div<CommentContainerProps>`
  padding: 10px;
  background-color: ${props => (props.currentUser ? props.theme["sky-blue"] : props.theme.grey)};
  color: ${props => (props.currentUser ? "white" : "black")};
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const Commenter = styled.div`
  font-weight: bold;
  display: flex;
`;

export const CommentBody = styled.div``;

export const AddVideoInputContainer = styled.div`
  display: inline-block;
  width: 300px;
  margin-right: 20px;
`;
