import React from "react";
import styled from "styled-components";
import LoginComponent from "../components/LoginComponent";
import loginBg from "../assets/login.png";

const ContainerBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url(${loginBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 70%;

  @media (max-width: 767px) {
    background-position-y: -100px;
  }
`;

const LoginContainer: React.FC = () => {
  return (
    <ContainerBackground>
      <LoginComponent />
    </ContainerBackground>
  );
};

export default LoginContainer;
