import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { useAuth } from "../contexts/AuthContext";

interface PrivateRouteProps extends RouteProps {
  component?: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { loggedIn } = useAuth();
  const hash = window.location.hash;

  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: hash ? RouteConstants.LOGIN + hash : RouteConstants.LOGIN,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
