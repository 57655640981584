import axios from "axios";
import qs from "qs";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/api/v1/appeal`;

export const getAllAppeals = (): Promise<any> => axios.get(`${API_URL}`).then(response => response.data);

export const getSingleAppeal = (appealId: number): Promise<any> =>
  axios.get(`${API_URL}/${appealId}`).then(response => response.data);

export const createAppeal = (appeal: Appeal): Promise<any> =>
  axios.post(`${API_URL}`, appeal).then(response => response);

export const deleteAppeal = (appealId: number): Promise<any> =>
  axios.delete(`${API_URL}/${appealId}`).then(response => response.data);

export const updateAppeal = (appeal: Appeal): Promise<any> =>
  axios.put(`${API_URL}`, appeal).then(response => response);

export const createComment = (appealId: number, comment: AppealComment): Promise<any> =>
  axios.post(`${API_URL}/${appealId}/comment`, comment).then(response => response);

export const editComment = (comment: AppealComment): Promise<any> =>
  axios.put(`${API_URL}/comment/${comment.id}`, { text: comment.text }).then(response => response);

export const getAppealsByCriteria = (criteria: AppealCriteria): Promise<any> =>
  axios
    .get(`${API_URL}`, {
      params: criteria,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    })
    .then(response => response);

export const getAllScoringAppealTags = (): Promise<any> => axios.get(`${API_URL}/tags`).then(response => response);

export const getAllRequestedBy = (): Promise<any> =>
  axios.get(`${API_URL}/getAllRequestedBy`).then(response => response.data);
