import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

type LoadingContextProps = {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const LoadingContext = createContext<LoadingContextProps>({ isLoading: false, setLoading: null });

const LoadingContextProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};

const useLoading = (): LoadingContextProps => {
  const context = useContext<LoadingContextProps>(LoadingContext);
  if (context === undefined) {
    throw new Error(`useLoading must be used within a AuthProvider`);
  }
  return context;
};

export { LoadingContextProvider, useLoading };
