import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useOkta } from "../customHooks/UseOkta";
import { RouteConstants } from "../constants/RouteConstants";

const OKTA_CONFIG = {
  url: process.env.OKTA_URL,
  clientId: process.env.OKTA_CLIENT_ID,
  issuer: process.env.OKTA_ISSUER,
  redirectUri: process.env.OKTA_REDIRECT_URI
};

const getOktaToken = (setter: (data: any) => void) => {
  const oktaTokenStorage = localStorage.getItem("okta-token-storage");
  if (oktaTokenStorage) {
    const { accessToken, idToken } = JSON.parse(oktaTokenStorage);
    setter({ accessToken: accessToken, email: idToken.claims.email });
  }
};

const OktaLogin: React.FC<RouteComponentProps> = ({ location: { pathname }, history, children }) => {
  const { login } = useAuth();

  const [oktaConfig, setOktaConfig] = useState(OKTA_CONFIG);

  const onLogin = () => {
    getOktaToken(login);
  };

  useEffect(() => {
    if (pathname === RouteConstants.LOGIN) {
      setOktaConfig(null);
    } else {
      setOktaConfig(OKTA_CONFIG);
    }
  }, [pathname]);

  useOkta(oktaConfig, onLogin);

  return <>{children}</>;
};

export default withRouter(OktaLogin);
