import axios from "axios";
import { formatDateForApi } from "../utils/DateUtils";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/api/v1/statsapi`;

export const getGamesOnDate = async (date: Date): Promise<Game[]> => {
  if (date) {
    const formattedDate = formatDateForApi(date);
    const response = await axios.get(`${API_URL}/${formattedDate}/games`);
    return response.data;
  } else {
    throw new Error("A date must be provided to getGamesOnDate");
  }
};

export const getOfficialScorerByGame = async (gamePk: number): Promise<Person> => {
  const response = await axios.get(`${API_URL}/${gamePk}/officialScorer`);
  return response.data;
};

export const getPlaysByGame = async (gamePk: number, allPlays: boolean): Promise<Play[]> => {
  const response = await axios.get(`${API_URL}/${gamePk}/plays?allPlays=${allPlays}`);
  return response.data;
};

export const getPlayersByGamePkAndPlayId = async (gamePk: number, playId: string): Promise<PlayPositions> => {
  const response = await axios.get(`${API_URL}/${gamePk}/plays/${playId}/players`);
  return response.data;
};

export const getPlayersByGamePk = async (gamePk: number): Promise<Player[]> => {
  const response = await axios.get(`${API_URL}/${gamePk}/players`);
  return response.data;
};

export const getOfficialScorers = async (): Promise<Person[]> => {
  const response = await axios.get(`${API_URL}/officialScorers`);
  return response.data;
};

export const getTeams = async (): Promise<Team[]> => {
  const response = await axios.get(`${API_URL}/teams`);
  return response.data;
};
