import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components";
import { PrimaryButton, BestTable, BestSelect, DatePickerInput, Loader } from "best-common-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { routeWithParams } from "../utils/RouteUtils";
import { AppealConstants } from "../constants/AppealConstants";
import { useAppeals } from "../contexts/AppealsContext";
import { getAllRequestedBy, getAppealsByCriteria } from "../api/AppealsApi";
import { formatDateForApi, getDiffInDays } from "../utils/DateUtils";
import { useRoles } from "../contexts/RolesContext";
import { getCommenters } from "../api/UserApi";
import ListReducer from "../reducers/ListReducer";
import { LoaderContainer } from "./elements/CommonElements";
import { getOfficialScorers, getTeams } from "../api/StatsApi";
import { DaysOpen } from "./appeal/AppealStyles";

const FilterRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
`;

const FilterBox = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

const TableContainer = styled.div`
  .ReactVirtualized__Grid.ReactVirtualized__List {
    width: 100% !important;
  }
  .bcr-table-body > div {
    width: 100% !important;
    height: 100% !important;
  }
`;

const CircleBadge = styled.span`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  margin-right: 10px;
  min-width: 1.5rem;
  min-height: 1.5rem;
  vertical-align: middle;
`;

// candidate to add to context if this is needed anywhere else
let commenters: any, commentersDispatch: any;

const AppealsTable: React.FC<RouteComponentProps> = ({ history }) => {
  const { isCreator, isApprover } = useRoles();

  const defaultFilter: DropdownOption = { value: 1, label: "Open" };
  const [appealsSort, setAppealsSort] = useState<SortInfo>({ key: AppealConstants.FIELDS.SEQUENCE, direction: "DESC" });
  const [statusFilter, setStatusFilter] = useState<DropdownOption>(defaultFilter);
  const [dateFilterStart, setDateFilterStart] = useState<Date>(null);
  const [dateFilterEnd, setDateFilterEnd] = useState<Date>(null);
  const [officialScorers, setOfficialScorers] = useState<Person[]>([]);
  const [scorerFilter, setScorerFilter] = useState<DropdownOption>(null);
  const [requestedByOptions, setRequestedByOptions] = useState<DropdownOption[]>([]);
  const [requestedByFilter, setRequestedByFilter] = useState<DropdownOption>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [homeTeamFilter, setHomeTeamFilter] = useState<DropdownOption>(null);
  const [teamFilter, setTeamFilter] = useState<DropdownOption>(null);
  const [tagOptions, setTagOptions] = useState<DropdownOption[]>([]);
  const [tagsFilter, setTagsFilter] = useState<Tag[]>([]);
  const [season, setSeason] = useState<DropdownOption>(AppealConstants.SEASON_OPTIONS[1]);
  [commenters, commentersDispatch] = useReducer(ListReducer, []);
  const { appeals, setAppeals, setAppeal, setIsEdit, tags } = useAppeals();
  const [displayAppeals, setDisplayAppeals] = useState<Appeal[]>([]);
  const [appealsLoading, setAppealsLoading] = useState(false);

  const createAppeal = () => {
    setIsEdit(false);
    history.push(`${routeWithParams(RouteConstants.APPEAL, { key: "id", value: "new" })}`);
  };

  const defaultColumnProperties = {
    sortable: true,
    dragable: true
  };

  const createCommentArray = () => {
    let columns: Array<any> = [
      {
        name: "Appeal #",
        key: AppealConstants.FIELDS.SEQUENCE,
        width: 90
      },
      {
        name: "Game Date",
        key: AppealConstants.FIELDS.GAME_DATE,
        width: 115
      },
      {
        name: "Days Open",
        key: "daysOpen",
        width: 95,
        formatter: (data: any) => {
          // historical appeals do not have a timestamp saved
          if (!data?.row?.startedTs && data?.row?.status !== "Open") {
            return <span />;
          }
          const days = getDiffInDays(!!data?.row?.startedTs ? data?.row?.startedTs : new Date(), data?.row?.createdTs);
          return <DaysOpen days={days}>{days}</DaysOpen>;
        }
      },
      {
        name: "Teams",
        key: "teams",
        width: 110,
        formatter: (data: any) => (
          <span>
            {data?.row?.awayTeam?.abbreviation} @ {data?.row?.homeTeam?.abbreviation}
          </span>
        )
      },
      {
        name: "Official Scorer",
        key: "officialScorer",
        formatter: (data: any) => <span>{data?.row?.officialScorer?.fullName}</span>
      },
      {
        name: "Inning",
        key: "inning",
        width: 75,
        formatter: (data: any) => (
          <span>
            {data?.row?.topInning ? "T" : "B"}
            {data?.row?.inning}
          </span>
        )
      },
      {
        name: "Batter",
        key: "batter",
        formatter: (data: any) => <span>{data?.row?.batter?.fullName}</span>
      },
      {
        name: "Pitcher",
        key: "pitcher",
        formatter: (data: any) => <span>{data?.row?.pitcher?.fullName}</span>
      },
      {
        name: "Requested By",
        key: AppealConstants.FIELDS.REQUESTED_BY,
        formatter: (data: any) => <span>{data?.row?.requestedBy?.fullName}</span>
      },
      {
        name: "OPS",
        key: "originalPlayScoring",
        width: 80
      },
      {
        name: "RPS",
        key: "revisedPlayScoring",
        width: 80
      },
      {
        name: "Status",
        key: AppealConstants.FIELDS.STATUS
      },
      {
        name: "Resolution",
        key: AppealConstants.FIELDS.RESOLUTION,
        width: 120
      }
    ];
    if (isApprover()) {
      columns.push({
        name: "Comment Status",
        key: AppealConstants.FIELDS.COMMENT_STATUS,
        width: 176,
        formatter: (data: any) => commentStatus(data?.row)
      });
    }
    return columns.map(c => ({ ...c, ...defaultColumnProperties }));
  };

  const columns = createCommentArray();

  interface RendererProps {
    renderBaseRow: Function;
    props: object;
  }

  const commentStatus = (row: any) => {
    return <span>{commenters.map((c: any) => commentStatusBubble(c, row?.comments))}</span>;
  };

  const commentStatusBubble = (commenter: string, comments: AppealComment[]) => {
    const filteredComments = comments?.filter(comment => comment?.fullName === commenter);
    const initials = commenter
      .split(" ")
      .map(segment => segment.charAt(0))
      .join("")
      .toUpperCase();
    const color = filteredComments?.length ? "white" : "#999999";
    const backgroundColor = filteredComments?.length ? "#0069d9" : "#f2f2f2";
    return <CircleBadge style={{ color: color, backgroundColor: backgroundColor }}>{initials}</CircleBadge>;
  };

  const RowRenderer: React.FC<RendererProps> = ({ renderBaseRow, ...props }) => {
    return <div style={{ cursor: "pointer" }}>{renderBaseRow(props)}</div>;
  };

  const showSingleAppeal = (rowIdx: number, appeal: Appeal) => {
    if (appeal) {
      setAppeal(appeal);
      setIsEdit(true);
      history.push(`${routeWithParams(RouteConstants.APPEAL, { key: "id", value: appeal.id })}`);
    }
  };

  const onSort = (key: string, direction: string) => setAppealsSort({ key, direction });

  useEffect(() => {
    const { key, direction } = appealsSort;
    const comparer = (a: any, b: any) => {
      let aVal, bVal;
      if (key === "batter" || key === "pitcher" || key === "officialScorer" || key === "requestedBy") {
        aVal = a[key].fullName;
        bVal = b[key].fullName;
      } else if (key === "teams") {
        aVal = `${a.awayTeam.abbreviation} @ ${a.homeTeam.abbreviation}`;
        bVal = `${b.awayTeam.abbreviation} @ ${b.homeTeam.abbreviation}`;
      } else if (key === "inning") {
        aVal = a.topInning ? `T${a.inning}` : `B${a.inning}`;
        bVal = b.topInning ? `T${b.inning}` : `B${b.inning}`;
      } else if (key === "daysOpen") {
        if (!a?.startedTs && a.status !== "Open") {
          aVal = -1;
        } else {
          aVal = getDiffInDays(!!a.startedTs ? a.startedTs : new Date(), a.createdTs);
        }
        if (!b?.startedTs && b.status !== "Open") {
          bVal = -1;
        } else {
          bVal = getDiffInDays(!!b.startedTs ? b.startedTs : new Date(), b.createdTs);
        }
      } else {
        aVal = a[key];
        bVal = b[key];
      }

      if (direction === "ASC") {
        return aVal > bVal ? 1 : -1;
      } else if (direction === "DESC") {
        return aVal < bVal ? 1 : -1;
      }
    };
    const sortedData = direction === "NONE" ? appeals : [...appeals].sort(comparer);
    setDisplayAppeals(sortedData);
  }, [appeals, appealsSort]);

  // Update filters
  useEffect(() => {
    if (
      season &&
      ((dateFilterStart && dateFilterEnd) ||
        (!dateFilterStart && !dateFilterEnd) ||
        scorerFilter ||
        homeTeamFilter ||
        teamFilter ||
        tagsFilter.length > 0 ||
        requestedByFilter)
    ) {
      const criteria: AppealCriteria = {
        status: statusFilter.value === 0 ? null : statusFilter.label ? statusFilter.label : null,
        year: season.value === 0 ? null : season.value ? season.value : null,
        startDate: dateFilterStart ? formatDateForApi(dateFilterStart) : null,
        endDate: dateFilterEnd ? formatDateForApi(dateFilterEnd) : null,
        officialScorerId: scorerFilter ? scorerFilter.value : null,
        homeTeamId: homeTeamFilter ? homeTeamFilter.value : null,
        teamId: teamFilter ? teamFilter.value : null,
        tagIds: tagsFilter?.length > 0 ? tagsFilter.map(t => t.value) : null,
        requestedById: requestedByFilter ? requestedByFilter.value : null
      };
      setAppealsLoading(true);
      getAppealsByCriteria(criteria).then(response => {
        setAppeals(response.data);
        setAppeal(null);
        setAppealsLoading(false);
      });
    }
  }, [
    season,
    statusFilter,
    dateFilterStart,
    dateFilterEnd,
    scorerFilter,
    homeTeamFilter,
    teamFilter,
    tagsFilter,
    requestedByFilter
  ]);

  useEffect(() => {
    getCommenters()
      .then(data => {
        commentersDispatch({ type: "replaceList", item: data });
      })
      .catch(function(error) {
        // do nothing, this is expected for certain user types
      });
    getOfficialScorers().then(data => {
      setOfficialScorers(data);
    });
    getTeams().then(data => {
      setTeams(data);
    });
    getAllRequestedBy().then(data => {
      setRequestedByOptions(
        data
          .map((person: Person) => ({ value: person?.id, label: person?.fullName }))
          .sort((a: DropdownOption, b: DropdownOption) => (a.label < b.label ? -1 : 1))
      );
    });
  }, []);

  useEffect(() => {
    setTagOptions(
      tags.map(tag => {
        return {
          value: tag?.id,
          label: tag?.label
        };
      })
    );
  }, [tags]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FilterRow>
          <FilterBox style={{ width: "200px" }}>
            <span>Appeal Status: </span>
            <BestSelect
              options={[{ value: 0, label: "All" }, ...AppealConstants.STATUS_OPTIONS]}
              onChange={setStatusFilter}
              value={statusFilter}
              isSearchable
            />
          </FilterBox>
          <FilterBox>
            <span>Season: </span>
            <BestSelect
              options={[...AppealConstants.SEASON_OPTIONS]}
              onChange={setSeason}
              value={season}
              isSearchable
            />
          </FilterBox>
          <FilterBox>
            <span>Start Date: </span>
            <DatePickerInput
              name="game-date-start"
              value={dateFilterStart ? dateFilterStart : ""}
              onChange={setDateFilterStart}
            />
          </FilterBox>
          <FilterBox>
            <span>End Date: </span>
            <DatePickerInput
              name="game-date-end"
              value={dateFilterEnd ? dateFilterEnd : ""}
              onChange={setDateFilterEnd}
            />
          </FilterBox>
        </FilterRow>
        <div style={{ flex: "2", textAlign: "right", paddingTop: "25px" }}>
          {isCreator() ? <PrimaryButton onClick={createAppeal}>Create</PrimaryButton> : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FilterRow>
          <FilterBox style={{ width: "200px" }}>
            <span>Tags: </span>
            <BestSelect
              options={tagOptions}
              onChange={setTagsFilter}
              value={tagsFilter}
              clearable
              isMulti
              isSearchable
            />
          </FilterBox>
          <FilterBox style={{ width: "200px" }}>
            <span>Scorer: </span>
            <BestSelect
              options={officialScorers
                .map(os => ({ value: os?.id, label: os?.fullName }))
                .sort((a: DropdownOption, b: DropdownOption) => (a?.label < b?.label ? -1 : 1))}
              onChange={setScorerFilter}
              value={scorerFilter}
              clearable
              isSearchable
            />
          </FilterBox>
          <FilterBox style={{ width: "200px" }}>
            <span>Requested By: </span>
            <BestSelect
              options={requestedByOptions}
              onChange={setRequestedByFilter}
              value={requestedByFilter}
              clearable
              isSearchable
            />
          </FilterBox>
          <FilterBox style={{ width: "150px" }}>
            <span>Home Team: </span>
            <BestSelect
              options={teams
                .map(team => ({ value: team?.id, label: team?.abbreviation }))
                .sort((a: DropdownOption, b: DropdownOption) => (a?.label < b?.label ? -1 : 1))}
              onChange={setHomeTeamFilter}
              value={homeTeamFilter}
              clearable
              isSearchable
            />
          </FilterBox>
          <FilterBox style={{ width: "150px" }}>
            <span>Team: </span>
            <BestSelect
              options={teams
                .map(team => ({ value: team?.id, label: team?.abbreviation }))
                .sort((a: DropdownOption, b: DropdownOption) => (a?.label < b?.label ? -1 : 1))}
              onChange={setTeamFilter}
              value={teamFilter}
              clearable
              isSearchable
            />
          </FilterBox>
        </FilterRow>
      </div>
      {appealsLoading && (
        <LoaderContainer style={{ marginTop: "50px" }}>
          <Loader />
        </LoaderContainer>
      )}
      {!appealsLoading &&
        (appeals?.length > 0 ? (
          <TableContainer>
            <BestTable
              columns={columns}
              data={displayAppeals}
              onRowClick={showSingleAppeal}
              rowRenderer={RowRenderer}
              sortColumn={appealsSort.key}
              sortDirection={appealsSort.direction}
              sortFunction={onSort}
              maxHeightOffset={220}
            />
          </TableContainer>
        ) : (
          <div style={{ marginTop: "50px", textAlign: "center", fontWeight: "bold" }}>
            No appeals found matching those criteria
          </div>
        ))}
    </>
  );
};

export default withRouter(AppealsTable);
