import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";
import { RolesConstants } from "../constants/RolesConstants";

type RolesContextProps = {
  userRoles: Role[];
  setUserRoles: Dispatch<SetStateAction<Role[]>>;
  isCreator: Function;
  isEditor: Function;
  isViewer: Function;
  isCommenter: Function;
  isApprover: Function;
};

const RolesContext = createContext<RolesContextProps>({
  userRoles: null,
  setUserRoles: null,
  isCreator: null,
  isEditor: null,
  isViewer: null,
  isCommenter: null,
  isApprover: null
});

const RolesContextProvider: React.FC = ({ children }) => {
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  const isCreator = (): boolean => userRoles.find(r => r.authority === RolesConstants.CREATOR) !== undefined;
  const isEditor = (): boolean => userRoles.find(r => r.authority === RolesConstants.EDITOR) !== undefined;
  const isViewer = (): boolean => userRoles.find(r => r.authority === RolesConstants.VIEWER) !== undefined;
  const isCommenter = (): boolean => userRoles.find(r => r.authority === RolesConstants.COMMENTER) !== undefined;
  const isApprover = (): boolean => userRoles.find(r => r.authority === RolesConstants.APPROVER) !== undefined;

  const value = {
    userRoles,
    setUserRoles,
    isCreator,
    isEditor,
    isViewer,
    isCommenter,
    isApprover
  };

  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};

const useRoles = (): RolesContextProps => {
  const context = useContext<RolesContextProps>(RolesContext);
  return context;
};

export { RolesContextProvider, useRoles };
