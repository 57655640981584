function ListReducer(state: any[], action: { type: string; item: any }) {
  switch (action.type) {
    case "add":
      return [...state, action.item];
    case "remove":
      const index = state.indexOf(action.item);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    case "replaceList":
      return action.item;
    default:
      throw new Error();
  }
}

export default ListReducer;
