import React from "react";
import { Router } from "react-router";
import History from "../components/History";
import { AuthContextProvider } from "./AuthContext";
import { LoadingContextProvider } from "./LoadingContext";
import HttpInterceptor from "../api/HttpInterceptor";
import { ThemeProvider } from "styled-components";
import { Theme } from "best-common-react";
import { RolesContextProvider } from "./RolesContext";

type WrapperProviderProps = {
  children: React.ReactNode;
};

const WrapperProvider: React.FC<WrapperProviderProps> = ({ children }) => (
  <Router history={History}>
    <AuthContextProvider>
      <RolesContextProvider>
        <LoadingContextProvider>
          <HttpInterceptor>
            <ThemeProvider theme={Theme}>{children}</ThemeProvider>
          </HttpInterceptor>
        </LoadingContextProvider>
      </RolesContextProvider>
    </AuthContextProvider>
  </Router>
);

export default WrapperProvider;
