export const formatDateForApi = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${year}-${formattedMonth}-${formattedDay}`;
};

export const formatTwelveHourTime = (date: Date): string => {
  let hours = date.getHours();
  let ampm = "pm";
  if (hours === 0) {
    hours = 12;
    ampm = "am";
  } else if (hours > 12) {
    hours -= 12;
  } else if (hours !== 12) {
    ampm = "am";
  }
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hours}:${minutes} ${ampm}`;
};

export const getDateFromString = (date: string): Date => {
  const match = date.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
  const dateArray = date.split("-");
  return match ? new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2])) : null;
};

export const getLocaleDateTime = (date: Date): string => {
  const dateObj: Date = new Date(date);
  return `${dateObj.toLocaleDateString("en-US")} ${dateObj.toLocaleTimeString("en-US")}`;
};

const MS_PER_DAY = 1000 * 60 * 60 * 24;
export const getDiffInDays = (date1: Date, date2: Date): number => {
  const timeDiff = Math.abs(new Date(date2).getTime() - new Date(date1).getTime());
  return Math.round(timeDiff / MS_PER_DAY);
};
