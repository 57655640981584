import React from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useLoading } from "../contexts/LoadingContext";

interface HttpInterceptorProps {
  children: React.ReactNode;
}

const HttpInterceptor: React.FC<HttpInterceptorProps> = ({ children }) => {
  const { logout } = useAuth();
  const { setLoading } = useLoading();

  let pendingRequest = 0;
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    pendingRequest++;
    setLoading(true);
    const { accessToken = {} } = JSON.parse(localStorage.getItem("okta-token-storage"));

    if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    (resp: AxiosResponse) => {
      pendingRequest--;
      if (!resp) return null;
      if (pendingRequest === 0) setLoading(false);
      return resp;
    },
    (err: AxiosError) => {
      pendingRequest--;
      if (pendingRequest === 0) setLoading(false);
      if (!!err.response && err.response.status === 401) {
        logout();
      }
      return Promise.reject(err);
    }
  );

  return <div>{children}</div>;
};

export default HttpInterceptor;
