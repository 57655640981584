import React from "react";
import { NavigationFromConfig, NavigationContainer, NavigationConfig } from "best-common-react";
import { useAuth } from "../contexts/AuthContext";

const AppealsContainer: React.FC = ({ children }) => {
  const { loggedIn, logout, userInfo } = useAuth();

  const config: NavigationConfig = {
    env: process.env.ENV,
    displayEnvIndicator: true,
    logoText: "Official Scoring Appeals",
    loggedIn: loggedIn,
    username: userInfo.email,
    usernameDropdownLinks: [
      {
        name: "Sign Out",
        to: "",
        onClick: logout
      }
    ]
  };

  return (
    <>
      <NavigationFromConfig config={config} />
      <NavigationContainer loggedIn={config.loggedIn} vertical={false} displayEnvIndicator={config.displayEnvIndicator}>
        {children}
      </NavigationContainer>
    </>
  );
};

export default AppealsContainer;
