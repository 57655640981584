import styled from "styled-components";

export const TitleRow = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.span`
  text-align: right;
  flex: 2;
`;

export const LoaderContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;
