import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

type AppealsContextProps = {
  appeals: Appeal[];
  setAppeals: Dispatch<SetStateAction<Appeal[]>>;
  appeal: Appeal;
  setAppeal: Dispatch<SetStateAction<Appeal>>;
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  tags: Tag[];
  setTags: Dispatch<SetStateAction<Tag[]>>;
};

const AppealsContext = createContext<AppealsContextProps>({
  appeals: null,
  setAppeals: null,
  appeal: null,
  setAppeal: null,
  isEdit: true,
  setIsEdit: null,
  showModal: false,
  setShowModal: null,
  tags: null,
  setTags: null
});

const AppealsContextProvider: React.FC = ({ children }) => {
  const [appeals, setAppeals] = useState<Appeal[]>([]);
  const [appeal, setAppeal] = useState<Appeal>(null);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tags, setTags] = useState<Tag[]>([]);

  const value = {
    appeals,
    setAppeals,
    appeal,
    setAppeal,
    isEdit,
    setIsEdit,
    showModal,
    setShowModal,
    tags,
    setTags
  };

  return <AppealsContext.Provider value={value}>{children}</AppealsContext.Provider>;
};

const useAppeals = (): AppealsContextProps => {
  const context = useContext<AppealsContextProps>(AppealsContext);
  return context;
};

export { AppealsContextProvider, useAppeals };
