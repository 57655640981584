import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { TextArea } from "best-common-react";
import { useAuth } from "../../contexts/AuthContext";
import { getLocaleDateTime } from "../../utils/DateUtils";
import { CommentBody, CommentContainer, Commenter } from "./AppealStyles";
import { editComment } from "../../api/AppealsApi";

type CommentProps = {
  comment: AppealComment;
  index: number;
  setComments: Function;
};
const Comment: React.FC<CommentProps> = ({ comment, index, setComments }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>(comment.text);

  const getCommentSubmittedBy = (comment: AppealComment): string => {
    const { userInfo } = useAuth();
    return comment.createdBy.toLowerCase() === userInfo.email.toLowerCase() ? "You" : comment.fullName;
  };

  const saveCommentText = () => {
    editComment({ ...comment, text: commentText }).then(response => {
      setComments((previous: AppealComment[]) =>
        previous.map((com: AppealComment) => (com.id === comment.id ? { ...com, text: commentText } : com))
      );
      setEditing(false);
    });
  };

  useEffect(() => {
    setCommentText(comment.text);
  }, [comment]);

  return (
    <CommentContainer key={comment.id} index={index} currentUser={getCommentSubmittedBy(comment) === "You"}>
      <Commenter>
        <span style={{ flexGrow: 1 }}>
          [{comment.createdTs ? getLocaleDateTime(comment.createdTs) : "Just Now"}] {getCommentSubmittedBy(comment)}:
        </span>
        {getCommentSubmittedBy(comment) === "You" ? (
          editing ? (
            <>
              <FontAwesomeIcon
                icon={faCheck}
                onClick={saveCommentText}
                style={{ color: "#00a651", cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setEditing(false)}
                style={{ color: "#dc3545", cursor: "pointer", marginLeft: "10px" }}
              />
            </>
          ) : (
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => setEditing(true)}
              style={{ color: "#ffffff", cursor: "pointer" }}
            />
          )
        ) : null}
      </Commenter>
      <CommentBody>
        {editing ? (
          <TextArea
            name={`comment-box-${comment.id}`}
            value={commentText}
            onChange={(event: any) => setCommentText(event.target.value)}
          />
        ) : (
          comment.text
        )}
      </CommentBody>
    </CommentContainer>
  );
};

export default Comment;
